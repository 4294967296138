<template>
  <div>
    <ul class="ztree">
      <v-tree
        v-for="(node,index) in treeData"
        :key="index"
        :check-box-type="checkBoxType"
        :all-open="allOpen"
        :before-click="beforeClick"
        :check-box="checkBox"
        :node-trigger="nodeTrigger"
        :index="index"
        :tree="node"
        :first="index===0"
        :last="treeData.length-1===index"
        :current-array="treeData"
        :parent-tree="node.parentTree"
        :root-data="treeData"
        :check-box-call-init="checkBoxCallInit"
        :check-box-call="checkBoxCall"
        :click-node="clickNode"
        :hidden-line="hiddenLine"
        :async="async"
        :async-call="asyncCall"
        :show-form="showForm"
        :params="params"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import vTree from './tree-core';

export default {
  name: 'Tree',
  components: { vTree },
  props: {
    treeNode: {
      type: Array,
      default() {
        return [];
      },
      required: true
    },
    allOpen: {
      type: Boolean,
      default: null,
      required: false
    },
    nodeTrigger: {
      type: Boolean,
      default: false,
      required: false
    },
    checkBox: {
      type: Boolean,
      default: false,
      required: false
    },
    checkBoxType: {
      type: Boolean,
      default: true,
      required: false
    },
    beforeClick: {
      type: Function,
      default: null
    },
    clickNode: {
      type: Function
    },
    asyncCall: {
      type: Function
    },
    hiddenLine: {
      type: Boolean,
      default: false,
      required: false
    },
    async: {
      type: Boolean,
      default: false,
      required: false
    },
    showForm: { // 显示形式 route 表示路由 popup 弹窗
      type: String,
      default: 'route'
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      treeData: [],
      line: '',
      first: true,
      allOpens: this.allOpen,
      checkedBoxCallArr: []
    };
  },
  computed: {
    ...mapGetters(['orgTree', 'departTree']),
    route() {
      let route = this.$route
      if (this.showForm === 'popup') {
        route = this.params
      }
      return route
    }
  },
  watch: {
    allOpen() {
      this.changeStatus();
    },
    params(val) {
      if (val.params && val.params.showForm === 'filter') {
        this.treeDataChange(val.params.value, this.treeData)
      }
    }
  },
  created() {
    this.init();
  },
  update() {
  },
  mounted() {
    /* 复选框回调 */
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$emit('checkBoxCall', this.checkedBoxCallArr);
  },
  methods: {
    init() {
      let tempData = [];
      if (this.route.params.typeName === 'orgId') {
        tempData = this.departTree;
      } else {
        tempData = this.orgTree;
      }
      const tempList = JSON.parse(JSON.stringify(tempData));
      // console.log(tempList);
      const initTree = (tree) => {
        for (let index = 0; index < tree.length; index++) {
          const m = tree[index];
          // eslint-disable-next-line no-prototype-builtins
          if (!m.hasOwnProperty('id')) {
            // eslint-disable-next-line no-prototype-builtins
            m.id = m.hasOwnProperty('id') ? m.id : null;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!m.hasOwnProperty('active')) {
            // eslint-disable-next-line no-prototype-builtins
            m.active = m.hasOwnProperty('active') ? m.active : false;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!m.hasOwnProperty('open')) {
            // eslint-disable-next-line no-prototype-builtins
            m.open = m.hasOwnProperty('open') ? m.open : false;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!m.hasOwnProperty('nodeTrigger')) {
            // eslint-disable-next-line no-prototype-builtins
            m.nodeTrigger = m.hasOwnProperty('nodeTrigger') ? m.nodeTrigger : false;
          }
          m.children = m.children || [];
          if (m.children.length > 0) initTree(m.children, m);
        }
      };
      initTree(tempList, null);

      this.treeData = tempList;
      this.line = 'line';
      if (this.first) { this.$emit('call', this.treeData); }
    },
    treeDataChange(id, treeData) {
      if (!Array.isArray(treeData)) return
      treeData.forEach(item => {
        if (item.id !== id) {
          item.active = false
        } else {
          item.active = true
        }
        this.treeDataChange(id, item.children)
      })
    },
    changeStatus() {
      const changeOpen = (data) => {
        data.forEach((d) => {
          d.open = this.allOpen;
          if (d.children) changeOpen(d.children);
        });
      };
      changeOpen(this.treeData);
    },
    checkBoxCallInit(arr) {
      arr.forEach((a) => {
        this.checkedBoxCallArr.push(a);
      });
    },
    checkBoxCall(arr, isAdd) {
      if (isAdd) {
        arr.forEach((a) => {
          this.checkedBoxCallArr.push(a);
        });
      } else {
        arr.forEach((a) => {
          if (this.checkBoxCall.length === 0) { return; }
          const key = (a.id ? a.id : null) + a.index + a.name;

          this.checkedBoxCallArr.forEach((ss, index) => {
            if (((ss.id ? ss.id : null) + ss.index + ss.name) === key) {
              this.checkedBoxCallArr.splice(index, 1);
            }
          });
        });
      }
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('checkBoxCall', this.checkedBoxCallArr);
    }
  },
};
</script>

<style  scoped>
  @import "../../assets/css/v-tree.css";
</style>
