<template>
  <div>
    <div class="search_outs">
      <div class="search_inline">
        <div
          class="search_box"
          @click="focus"
        >
          <i class="icon-joyo search_icon">&#xe71e;</i>
          <form
            class="search_input"
            action="javascript:void 0"
          >
            <input
              :value="value"
              :placeholder="placeholder"
              :readonly="true"
            >
          </form>
        </div>
        <div
          v-if="needSreen"
          class="search_btn"
          @click="toScreen"
        >
          <i class="icon-joyo space_icon">&#xe71d;</i>
          <span class="space">筛选</span>
        </div>
      </div>
    </div>
    <search-veiw
      v-if="searchBool"
      ref="searchVeiw"
      :data="value"
      :func="func"
      :search-type="searchType"
      :show-form="showForm"
      :params="params"
      @conceal="conceal"
      @getLists="getLists"
      @option="option"
      @toTransfer="toTransfer"
      @clear="clear"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import searchVeiw from '_c/search';

import optionTree from '@/mixins/optionTree';

export default {
  components: {
    searchVeiw
  },
  mixins: [optionTree],
  model: {
    prop: 'valueModel',
    event: 'updateValue'
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容'
    },
    needSreen: {
      type: Boolean,
      default: true
    },
    func: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    searchType: {
      type: String,
      default: ''
    },
    valueModel: {
      type: [String, Object, Array, Number],
      default: ''
    }
    // router: {
    //   type: String
    // },
  },
  data() {
    return {
      searchBool: false,
      value: null,
      listData: []
    };
  },
  watch: {
    value(val) {
      this.$emit('updateValue', val)
    }
  },
  methods: {
    ...mapMutations(['CLEAR_SCREEN', 'SET_KEEPALIVE']),
    focus() {
      this.searchBool = true;
      this.$nextTick(() => {
        this.$refs.searchVeiw.focusd();
      })
    },
    conceal(bool) {
      this.searchBool = bool;
      this.$emit('getLists');
    },
    option(data) {
      this.value = data;
      this.$emit('searchValue', this.value);
      this.searchBool = false;
    },
    clear(data) {
      this.value = data;
      this.$emit('searchValue', this.value);
    },
    toScreen() {
      this.SET_KEEPALIVE([`${this.route.name}`]);
      this.$emit('tofilter');
    },
    getLists(value) {
      this.value = value
      this.$emit('getLists', value);
      this.searchBool = false;
    },
    toTransfer(orgsales) {
      this.$emit('toTransfer', orgsales);
    }
  }
};
</script>

<style  lang="scss"  scoped>
.search_outs {
  position: relative;
  top:0;
  left:0;
  width:100%;
  // overflow: hidden;
  background-color: $color-FFF;
  .search_inline{
    display: flex;
    height: 36px;
    padding: 16px 15px 8px 15px;
    .search_btn{
      width:74px;
      margin-left: 10px;
      color: $color-999;
      font-size: 15px;
      line-height: 36px;
      .space{
        margin-left:10px ;
        font-size: $font-15;
      }
      .space_icon{
        font-size: 18px;
      }
    }
    .search_box{
      display: flex;
      flex: 1;
      align-items:center;
      justify-content:flex-start;
      height: 33px;
      background-color: $color-F4;
      // border: 1px solid $color-E6;
      border-radius: 5px ;
      input {
        width: 100%;
        margin-top: 3px;
        // padding: 10px 0;
        line-height: 1;
        background-color: rgba(0, 0, 0, 0);
        border: 0;  // 去除未选中状态边框
        outline: none; // 去除选中状态边框// 透明背景
      }
      .search_icon{
        width:33.5px;
        height: 25px;
        color:$color-CCC;
        font-size:$font-14 ;
        line-height: 25px;
        text-align: center;
      }
      .search_input{
        flex: 1;
        font-size: 12px;
      }
    }
  }
}
</style>
