<template>
  <div class="section">
    <show-search
      class="header"
      :placeholder="placeholder"
      :need-sreen="needSreen"
      :search-type="searchType"
      :func="func"
      :show-form="showForm"
      :params="params"
      @toTransfer="toTransfer"
    />
    <div class="main">
      <div class="main-title">
        今元集团
      </div>
      <ul>
        <li>
          <tree
            ref="initTree"
            :tree-node="treeNode"
            :all-open="allOpen"
            :node-trigger="nodeTrigger"
            :check-box="checkBox"
            :before-click="beforeClick"
            :check-box-type="checkBoxType"
            :click-node="clickNode"
            :hidden-line="hiddenLine"
            :show-form="showForm"
            :params="params"
            @checkBoxCall="checkBoxCall"
            @call="call"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { clueTransfer } from '_api/clue';
import { querySingleByParam, queryStaffByParam } from '_api/global';
import showSearch from '_c/search/showSearch';
import Tree from '_c/vTree/tree';

import optionTree from '@/mixins/optionTree'

export default {
  components: {
    showSearch,
    Tree
  },
  mixins: [optionTree],
  data() {
    return {
      placeholder: '搜索人员',
      needSreen: false,
      treeNode: [],
      allOpen: null,
      allClose: null,
      nodeTrigger: true,
      /* 是否开启复选框 */
      checkBox: false,
      clickFirstNode: false,
      checkFirstCheckBox: false,
      /* 是否级联选中 */
      checkBoxType: true,
      tree: [],
      trees: [],
      firstIsOpen: true,
      hiddenLine: false,
      async: true,
      func: queryStaffByParam,
      searchType: 'personnelTree'
    };
  },
  mounted() {},
  created() {
    if (this.route.params.typeName === 'orgId') {
      this.nodeTrigger = false;
      this.func = querySingleByParam;
      this.searchType = 'departTree';
      this.placeholder = '搜索部门';
    }
  },
  methods: {
    /* 复选框选中/取消 回调 */
    checkBoxCall() { // 参数 checkBox
    },
    asyncCheckBoxCall() { // 参数 checkBox
    },
    beforeClick(node) {
      // 可操作内部
      if (this.clickFirstNode) return node.id !== '1';
      return true;
    },
    beforeCheckBox(node) {
      // console.log('checkFirstCheckBox', node);
      if (this.checkFirstCheckBox) return node.id !== '1';
      return true;
    },
    checkBoxHandle() { // 参数value
    },
    openFirst() {
      this.trees[0].open = !this.trees[0].open;
      this.firstIsOpen = this.trees[0].open;
    },
    /* 回调初始化后的数据 以后修改tree实现对节点的操作即可 */
    call(data) {
      this.trees = data;
    },
    callAsync() {
      // this.trees = data;
    },
    toTransfer(orgsales) {
      const routeType = this.route.params;
      const params = {
        clueId: routeType.id,
        sourceOrgId: routeType.sourceOrgId,
        sourceUserId: routeType.sourceUserId,
        targetOrgId: orgsales.transferSales.orgId,
        targetUserId: orgsales.transferSales.value
      };
      const targetUser = [params];
      clueTransfer({ targetUser }).then(res => {
        if (res.flag) {
          this.$showToast('转移成功');
          this.$router.go(-1);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    /* 点击节点信息 上个点击节点信息 */
    clickNode(data) {
      const { typeName } = this.route.params;
      const orgsales = {
        [typeName]: {
          name: data.orgTitle,
          value: data.id,
          orgId: data.orgId
        }
      };
      // 临时添加 筛选
      if (this.route.params && this.route.params.showForm === 'filter') {
        if (data.children.length === 0 || typeName === 'orgId') {
          this.SET_FILTER(orgsales)
          this.SET_TIME(new Date().getTime())
          // this.$router.go(-1);
        }
        return
      }
      if (data.children.length === 0) { // 人员
        this.storageVuex(orgsales);
      } else if (typeName === 'orgId') { // 部门的
        switch (this.route.params.routerName) {
          case 'business-filter':
            this.BUS_FILTER(orgsales);
            break;
          case 'follow-filter':
            this.FOLLOW_FILTER(orgsales);
            break;
          case 'workreport-filter':
            this.WORKREPORT_FILTER(orgsales);
            break;
        }
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss"  scoped>
  .section{
    height:100%;
    background-color: $color-FFF;
  }
  .main{
    margin-left:15px;
    text-align: left;
    .main-title{
      margin:20px 0 ;
      color: $color-333;
      font-weight: 600;
      font-size: 18px;
    }
    ul{
      display: inline-block;
      width: 100%;
      height: calc(100vh - 124px);
      overflow-x: hidden;
      overflow-y: scroll;
      li{
        float: left;
        width: 100%;
        list-style: none;
      }
    }
  }
</style>
