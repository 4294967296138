<template>
  <li>
    <!-- <span
      v-if="vif()"
      title=""
      @click="vShow(tree)"
      class="button  switch"
      :class="vTreeFirstSpan()"
    ></span>
    <span v-else title="" class="button switch " :class="classes()"></span>

    <span
      v-if="checkBox"
      @click="selectCheckBox(tree)"
      class="button chk"
      :class="tree.checked ? 'checkbox_true_full' : 'checkbox_false_full'"
    ></span> -->

    <a
      target="_blank"
      :class="aClass()"
      :title="tree.orgTitle"
      class="classA"
    >
      <!-- :style="{marginLeft: tree.children.length ? 0  : -18 + 'px' }" -->
      <i
        class="icon-joyo button iconfont"
        @click="vShow(tree)"
      >{{
        iconCss()
      }}</i>
      <!--icon -->
      <span
        v-if="tree.children.length === 0 && $route.params.typeName !== 'orgId'"
        class="insertImg"
      >
        <img
          v-if="tree.children.length === 0"
          src="../../assets/img/person.png"
          alt=""
        />
      </span>
      <!--img-->
      <div
        class="node_div"
        @click="selectNode"
      >
        <span class="node_name">{{ tree.orgTitle }}</span>
        <span class="node_num">{{
          tree.children.length ? `(${tree.children.length})` : ""
        }}</span>
        <i
          v-if="needActive"
          class="icon-joyo iconfont floatr"
        >&#xe728;</i>
      </div>
    </a>
    <ul :class="line">
      <tree-core
        v-for="(tr, i) in tree.children"
        v-show="tree.open"
        :key="i"
        :check-box-type="checkBoxType"
        :all-open="tr.allOpen"
        :first="false"
        :check-box="checkBox"
        :node-trigger="nodeTrigger"
        :index="i"
        :tree="tr"
        :current-array="tree.children"
        :parent-tree="tr.parentTree"
        :root-data="rootData"
        :click-node="clickNode"
        :before-click="beforeClick"
        :check-box-call-init="checkBoxCallInit"
        :check-box-call="checkBoxCall"
        :last="tree.children.length - 1 === i"
        :hidden-line="tr.hiddenLine"
        :async="async"
        :async-call="asyncCall"
        @checkedBoxV="checkedBoxV"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeCore',
  props: {
    tree: {
      type: Object,
      required: true
    },
    allOpen: {
      type: Boolean,
      default: null,
      required: false
    },
    nodeTrigger: {
      type: Boolean,
      default: false,
      required: false
    },
    checkBox: {
      type: Boolean,
      default: false,
      required: false
    },
    checkBoxType: {
      type: Boolean,
      default: true,
      required: false
    },
    beforeClick: {
      type: Function,
      default: null
    },
    last: {
      type: Boolean,
      default: null,
      required: false
    },
    first: {
      type: Boolean,
      default: true,
      required: false
    },
    currentArray: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    rootData: {
      type: Array,
      required: true
    },
    parentTree: {
      type: Object,
      required: false
    },
    checkBoxCallInit: {
      type: Function
    },
    checkBoxCall: {
      type: Function
    },
    clickNode: {
      type: Function
    },
    asyncCall: {
      type: Function
    },
    hiddenLine: {
      type: Boolean,
      default: false,
      required: false
    },
    async: {
      type: Boolean,
      default: false,
      required: false
    },
    showForm: { // 显示形式 route 表示路由 popup 弹窗
      type: String,
      default: 'route'
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      line: '',
      currentTree: this.tree,
      asyncLoading: false
    };
  },
  computed: {
    needActive() {
      return this.tree.active && (this.route.params.typeName === 'orgId' || (this.tree.children.length === 0 && this.route.params.typeName !== 'orgId'));
    },
    vif() {
      return function() {
        return this.async ? true : this.tree.children.length > 0;
      };
    },
    vTreeFirstSpan() {
      return function() {
        return this.hiddenLine
          ? this.tree.open
            ? 'noline_open treenode_switch'
            : 'noline_close treenode_switch'
          : this.currentArray.length - 1 === this.index
            ? this.tree.open
              ? 'bottom_open'
              : 'bottom_close'
            : this.tree.open
              ? 'roots_open'
              : 'roots_close';
      };
    },
    classes() {
      return function() {
        return this.hiddenLine
          ? 'noline_docu'
          : this.first
            ? 'roots_docu'
            : this.currentArray.length - 1 === this.index
              ? 'bottom_docu'
              : 'center_docu';
      };
    },
    iconCss() {
      return function() {
        return this.tree.children.length > 0
          ? this.tree.open
            ? '\ue730'
            : '\ue72f'
          : ' ';
      };
    },
    load() {
      return function() {
        return this.asyncLoading
          ? 'background:url(../../static/img/loading.gif) 0 0 no-repeat'
          : '';
      };
    },
    aClass() {
      return function() {
        return this.tree.active ? 'curSelectedNode' : '';
      };
    },
    route() {
      let route = this.$route
      if (this.showForm === 'popup') {
        route = this.params
      }
      return route
    }
  },
  watch: {
    tree: {
      handler(oldV, newV) {
        // eslint-disable-next-line vue/no-mutating-props
        if (newV.checked) this.tree.checked = newV.checked;
      },
      deep: true
    }
  },
  created() {
    this.coreInit();
  },
  methods: {
    coreInit() {
      this.line = this.hiddenLine
        ? ''
        : this.currentArray.length - 1 === this.index
          ? ''
          : 'line';
      if (this.tree.parentTree && this.tree.checked) {
        /* 方案一 Scheme 1 */
        const checkedBoxArr = [];
        checkedBoxArr.push(this.tree);
        const selectParent = (data) => {
          if (!data.checked) {
            data.checked = this.tree.checked;
            checkedBoxArr.push(data);
          }
          if (data.parentTree) {
            selectParent(data.parentTree);
          } else {
            this.checkBoxCallInit(checkedBoxArr);
          }
        };
        selectParent(this.currentTree);
        /* 方案二 Scheme 2 */
        // this.$emit('checkedBoxV')
      }
    },
    checkedBoxV() {
      // eslint-disable-next-line vue/no-mutating-props
      this.tree.checked = true;
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('checkedBoxV');
    },
    pNode(tree) {
      return tree.open;
    },
    vShow(tree) {
      /* 需要有异步加载  need add async loading */
      /* 默认异步加载给他按钮 当加载后才能判别时候有子节点 */
      if (this.async && !tree.open && tree.children.length === 0) {
        // eslint-disable-next-line
        this.asyncCall.call(this, tree, this.asyncBack);
        this.asyncLoading = true;
      }
      tree.open = !tree.open;
    },
    asyncBack(requestDataArr) {
      if (typeof requestDataArr) this.addNode(requestDataArr);
      this.asyncLoading = false;
    },
    selectNode() {
      // eslint-disable-next-line
      const isClick = this.beforeClick.call(this, this.tree);
      if (isClick) {
        // 清除其它
        let pro = null;
        const clearStyle = (data) => {
          for (let i = 0; i < data.length; i++) {
            const d = data[i];
            if (d.active) {
              pro = d;
              d.active = !d.active;
            } else if (d.children) {
              clearStyle(d.children);
            }
          }
        };
        clearStyle(this.rootData);
        // eslint-disable-next-line vue/no-mutating-props
        this.tree.active = true;
        if (this.nodeTrigger) {
          // eslint-disable-next-line vue/no-mutating-props
          this.tree.open = !this.tree.open;
        }
        // eslint-disable-next-line
        this.clickNode.call(this, this.tree, pro);
      }
    },
    addNode(arr) {
      try {
        arr.forEach((a) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.tree.children.push({
            id: a.id ? a.id : null,
            name: a.name,
            open: a.open ? a.open : false,
            checked: a.checked ? a.checked : false,
            checkBox: a.checkBox ? a.checkBox : false,
            nodeTrigger: a.nodeTrigger ? a.nodeTrigger : this.nodeTrigger,
            checkBoxType: this.checkBoxType,
            last: false,
            first: false,
            active: false,
            async: this.async,
            hiddenLine: this.hiddenLine,
            parentTree: this.tree,
            children: []
          });
        });
      } catch (e) {
        // eslint-disable-next-line
        console.error(
          'The asynchronous callback parameter must be an array,异步回调参数必须是数组',
        );
      }
    },
    selectCheckBox(tree) {
      tree.checked = !tree.checked;
      const checkedBoxArr = [];
      checkedBoxArr.push(tree);
      if (this.checkBoxType) {
        /* 级联 */
        const changeChecked = (data) => {
          data.forEach((d) => {
            checkedBoxArr.push(d);
            d.checked = tree.checked;
            if (d.children) changeChecked(d.children);
          });
        };
        changeChecked(tree.children);

        const checkChildren = (data) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].checked) return true;
          }
        };
        const selectParent = (data) => {
          if (data.children.length > 1) {
            const childExistsThis = [];
            data.children.forEach((m, index) => {
              if (index !== this.index) childExistsThis.push(m);
            });
            if (
              childExistsThis &&
              data.checked &&
              checkChildren(childExistsThis)
            ) { return; }
          }
          checkedBoxArr.push(data);
          data.checked = tree.checked;
          if (data.parentTree) selectParent(data.parentTree);
        };
        if (this.parentTree) selectParent(this.parentTree);
      }

      this.checkBoxCall(checkedBoxArr, tree.checked);
    }
  },
};
</script>

<style lang="scss" scoped>
.classA {
  display: flex;
  align-items: center;
}
.iconfont {
  color: $color-font;
  font-size: $font-14;
}
.floatr{
  float: right;
  margin-right:15px;
}
.node_div{
  display: inline-block;
  width: 100%;
}
.node_name {
  margin: 0 11px 0 15px;
  color: $color-333;
  font-size: $font-15;
}
.node_num {
  color: $color-999;
  font-size: $font-15;
}
.insertImg {
  width: 30px;
  height: 30px;
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
